/*
 * @Description: 国安西班牙语
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-09-25 18:08:05
 */
export default {
    "NT.login":"Iniciar sesión",
    "NT.register":"Registro",
    "NT.logout":"Salida",
    "NT.MyOrder":"Mi pedido",
    "NT.logoBox.placeholder": "Palabras clave",
    "NT.logoBox.search": "Buscar",
    "NT.logoBox.AdvancedSearch": "Búsqueda avanzada",
    "NT.nav.Products": "Productos",
    "NT.nav.news": "Noticias",
    "NT.ViewPrice": "Ver el precio",
    "NT.SameFactory": "La misma fábrica",
    "NT.newPro": "Nuevos productos",
    "NT.AnotherBatch": "Cambiar un lote",
    "NT.ViewMore": "Ver más",
    "NT.hotPro": "Productos de venta caliente",
    "NT.hotVidio": "Video caliente",
    "NT.proUnBoxVidio": "Video de apertura del producto",
    "NT.Search": "Buscar",
    "NT.Reset": "Restablecer",
    "NT.ItemNO": "Número del proyecto",
    "NT.MTCode": "Número de la empresa",
    "NT.FTYCode": "Número de fábrica",
    "NT.Kind": "Tipo",
    "NT.Size": "Especificaciones",
    "NT.Price": "Precio",
    "NT.Date": "Fecha",
    "NT.Sort": "Ordenar",
    "NT.DateDesc": "Fecha Bajar",
    "NT.DateAsc": "Fecha Subir",
    "NT.PriceDesc": "Precio Bajar",
    "NT.PriceAsc": "Precio Subir",
    "NT.NO.Desc": "Número Bajar",
    "NT.NO.Asc": "Número Subir",
    "NT.PleaseEnterTheProductNameKeyword": "Introduzca la palabra clave del nombre del producto",
    "NT.PleaseSelect": "Por favor, elija",
    "NT.PleaseLength": "Largo",
    "NT.PleaseWidth": "Ancho",
    "NT.PleaseHeight": "Alto",
    "NT.PleaseMinPrice": "Precio mínimo",
    "NT.PleaseMaxPrice": "Precio máximo",
    "NT.PleaseStartDate": "Fecha de inicio",
    "NT.PleaseEndDate": "Fecha de finalización",
    "NT.UserAccount": "Cuenta",
    "NT.password": "Contraseña",
    "NT.GROUPSEARCH": "Búsqueda integral",
    "NT.Video_playback": "Reproducción de vídeo",
    "NT.loginMsg": "Acceso a su cuenta y historial de pedidos",
    "NT.onUserAccount": "No hay cuenta",
    "NT.SignUp": "Registro",
    "NT.ForgetPassword": "Olvida la contraseña",
    "NT.resetPasswords": "Restablecer la contraseña",
    "NT.emailAddress": "Buzón",
    "NT.verifyCode": "Código",
    "NT.getVerifyCode": "Acceso Código",
    "NT.newPassword": "Nueva contraseña",
    "NT.confirmPassword": "Confirmar contraseña",
    "NT.Submit": "Presentación",
    "NT.SignUpAccount": "Cuenta registrada",
    "NT.LoginOnly": "Solo para iniciar sesión",
    "NT.Name": "Nombre",
    "NT.NameMsg": "Introduzca su nombre, de 2 a 30 caracteres",
    "NT.passwordMsg": "Al menos 6 caracteres",
    "NT.requiredFields": "*obligatorio",
    "NT.Email": "Buzón",
    "NT.EmailMsg": "Por favor, introduzca el buzón. Para iniciar sesión.",
    "NT.gender": "Género",
    "NT.man": "Hombre",
    "NT.woman": "Señora",
    "NT.customerSource": "Fuente del cliente",
    "NT.salesman": "Vendedor",
    "NT.Country": "País",
    "NT.Company": "Empresa",
    "NT.duty": "Cargo",
    "NT.tel": "Teléfono",
    "NT.mobile": "Teléfono móvil",
    "NT.fax": "Fax",
    "NT.Yahoo": "Yahoo",
    "NT.website": "Sitio web",
    "NT.address": "Dirección",
    "NT.postalCode": "Código postal",
    "NT.ExistingAccount": "Cuenta existente",
    "NT.DirectLogin": "Iniciar sesión directamente",
    "NT.productPrice": "Precio del producto",
    "NT.ProductNumber": "Número de producto",
    "NT.Packing": "Método de embalaje",
    "NT.OuterBoxCBM": "Volumen de la caja exterior",
    "NT.OuterBoxCUFT": "Volumen de la caja exterior",
    "NT.Innerboxes": "Número de cajas interiores",
    "NT.OuterQTY": "Volumen de la caja exterior",
    "NT.GWAndNW": "Peso bruto / neto",
    "NT.productSize": "Especificaciones del producto",
    "NT.packageSize": "Especificaciones de embalaje",
    "NT.cartonSize": "Especificaciones de la caja exterior",
    "NT.addCart": "Añadir carrito de compras",
    "NT.delCart": "Fuera del carrito de la compra",
    "NT.product": "Productos",
    "NT.NewsUpdates": "Novedades periodísticas",
    "NT.ReleaseDate":"Fecha de publicación",
    "NT.MyCart":"Mi carrito de compras",
    "NT.ID":"ID",
    "NT.picture":"Imagen",
    "NT.ArticleNo": "Número",
    "NT.Description": "Nombre del producto",
    "NT.CartOuterQTY":"Fuera",
    "NT.CBM":"CBM",
    "NT.CTNS":"CTNS",
    "NT.totalQTY": "Total",
    "NT.TotalVolume":"Total CBM",
    "NT.total":"Total",
    "NT.delete":"Eliminar",
    "NT.checkAll":"Selección completa",
    "NT.clear":"Vaciado",
    "NT.totalItems": "Número total",
    "NT.totalCTNS": "Volumen total de cajas",
    "NT.noData": "No hay datos por el momento",
    "NT.totalPrice": "Precio total",
    "NT.systemTip": "Consejos del sistema",
    "NT.confirm": "Determinar",
    "NT.cancel": "Cancelación",
    "NT.deleteData": "¿¿ quieres eliminar este dato?",
    "NT.clearCartData": "¿¿ vaciar todos los carritos de la compra?",
    "NT.submitTip": "¿Confirmar la presentación del pedido?",
    "NT.MyOrder": "Mi pedido",
    "NT.PleaseOrderNum": "Por favor, introduzca el número de pedido.",
    "NT.number": "Número único",
    "NT.viewDetails": "Ver detalles",
    "NT.orderTime": "Tiempo de creación",
    "NT.linkman": "Contactos",
    "NT.sendDate": "Tiempo de envío",
    "NT.expressNumber": "Número de la lista de mensajería",
    "NT.orderStatus": "Estado del pedido",
    "NT.unshipped": "No enviado",
    "NT.shipped": "Enviado",
    "NT.SiteNavigation": "Navegación del sitio web",
    "NT.ContactUs": "Contáctanos",
    "NT.FollowUs": "Preste atención a nosotros",
    "NT.Landline": "Avión fijo",
    "NT.Email": "Buzón",
    "NT.Phone": "Móvil",
    "NT.WeChat": "WeChat",
    "NT.Technic": "Soporte técnico",
    "NT.Software": "HongSheng Soft",
    "NT.detailBtn": "Detalles del producto",
    "NT.noDetailBtn": "No Detalles del producto",
    "NT.classify": "Clasificación",
    "NT.exclusive": "Recomendaciones exclusivas para",
    "NT.loading": "Cargado...",
    "NT.updateTime": "Tiempo",
    "NT.SearchMsg": "Los criterios de búsqueda anteriores son opcionales para cualquiera o más búsquedas integrales.",
    "NT.MessageTitle": "Enviar sus necesidades en línea",
    "NT.MessageMsg": "Responderemos a su correo electrónico en 24 horas, excepto en días festivos.",
    "NT.MessageName": "Tu nombre",
    "NT.MessageTel": "Tu teléfono móvil",
    "NT.MessageEmail": "Tu buzón",
    "NT.MessageNeeds": "Tus necesidades",
    "NT.SendInquiry": "Investigación",
    "NT.EnquiryList": "Investigación",
    "NT.enquiry": "Investigación",
    "NT.MyEnquiryList": "Mi formulario de consulta",
    "NT.Remark": "Nota",
    "NT.Enquirys": "Deje su correo electrónico para facilitar el contacto.",
    "NT.proClass": "Clasificación de productos",
    "NT.submitOrder": "¿Confirmar la presentación de la consulta?",


    "NT.Co-branding": "Marca cooperativa",
    "NT.Co-brandingMsg": "Ya hay más de 100 empresas que cooperan en profundidad con nuestra empresa.",
    "NT.recommendPro": "Productos recomendados",
    "NT.Blog": "Blog",
    "NT.More": "More",
    "NT.nav.Home": "Casa",
    "NT.nav.category": "Categoría",
    "NT.nav.Hot": "Venta caliente",
    "NT.nav.Gift": "Regalos",
    "NT.nav.New": "Nuevos productos",
    "NT.nav.video": "Vídeo",
    "NT.nav.Blog": "Blog",
    "NT.nav.About": "Sobre nosotros",
    "NT.nav.contact": "Contáctanos",
    "NT.nav.FooterNew": "Nuevos productos",
    "NT.news.title": "Todos los blogs",
    "NT.noMore": "No hay más",
    "NT.top.cart": "Carrito de compras",
    "NT.fomcat.title": "¿¿ por qué se eligió la marca fomcat?",
    "NT.fomcat.titleMsg": "¡¡ la ventaja está aquí! ¡Las ventajas de los mayoristas de juguetes fomcat no pueden ser ignoradas, ¡ veamos por qué somos sus mejores socios!",
    "NT.fomcat1.title1": "Una variedad de líneas de productos",
    "NT.fomcat1.Msg1": "Cubre juguetes para niños, juguetes de control remoto, modelos y colecciones, etc., para satisfacer las necesidades de diferentes clientes. ¡¡ no importa la edad a la que se enfrente su tienda, tenemos la opción perfecta!",
    "NT.fomcat2.title1": "Garantía de calidad del material",
    "NT.fomcat2.Msg1": "¡Seleccionamos materiales de alta calidad para crear juguetes seguros y confiables para los niños, sometidos a pruebas de mercado, ¡ para que los padres y los niños puedan disfrutar con tranquilidad!",
    "NT.fomcat3.title1": "Precios al por mayor competitivos",
    "NT.fomcat3.Msg1": "¡¡ ofrecemos precios al por mayor muy competitivos que le brindan un mayor margen de beneficio!",
    "NT.fomcat4.title1": "Cooperación fácil",
    "NT.fomcat4.Msg1": "Póngase en contacto con nosotros en cualquier momento y nuestro equipo resolverá sus problemas y necesidades de manera eficiente. ¡¡ nuestro objetivo es establecer una relación de cooperación exitosa a largo plazo!",
    "NT.fomcat5.title1": "Fácil pedido",
    "NT.fomcat5.title2": "Servicio íntimo",
    "NT.fomcat5.Mag1": "¡¡ su éxito es nuestro éxito! ¡¡ elige la marca fomcat y construye un hermoso parque de juguetes con nosotros!",




}   
