/*
 * @Description: 项目文件路径
 * @Author: zml
 * @Date: 2022-12-29 17:18:10
 * @LastEditors: zml
 * @LastEditTime: 2023-11-08 10:43:10
 */
export default {
  components: {
    /**
     * 模板1
     * themeTemplateOne
     */
    contactDetailOne: () => import('@/components/themeTemplateOne/page/detail/index'),
    aboutDetailOne: () => import('@/components/themeTemplateOne/page/detail/index'),
    pageCartOne: () => import('@/components/themeTemplateOne/page/cart/index'),
    pageHomeOne: () => import('@/components/themeTemplateOne/page/home'),
    pageOrderDetailOne: () => import('@/components/themeTemplateOne/page/order/detail'),
    pageOrderOne: () => import('@/components/themeTemplateOne/page/order/index'),
    pageProDetailOne: () => import('@/components/themeTemplateOne/page/product/proDetail.vue'),
    pageProductOne: () => import('@/components/themeTemplateOne/page/product/index.vue'),
    pageAddressOne: () => import('@/components/themeTemplateOne/page/address/index'),

    /**
     * 模板2 小玩具
     * themeTemplateTow
     */
    contactDetailTow: () => import('@/components/themeTemplateTow/page/detail/index'),
    aboutDetailTow: () => import('@/components/themeTemplateTow/page/detail/index'),
    pageCartTow: () => import('@/components/themeTemplateTow/page/cart/index'),
    pageHomeTow: () => import('@/components/themeTemplateTow/page/home'),
    pageOrderDetailTow: () => import('@/components/themeTemplateTow/page/order/detail'),
    pageOrderTow: () => import('@/components/themeTemplateTow/page/order/index'),
    pageProDetailTow: () => import('@/components/themeTemplateTow/page/product/proDetail.vue'),
    pageProductTow: () => import('@/components/themeTemplateTow/page/product/index.vue'),
    pageAddressTow: () => import('@/components/themeTemplateTow/page/address/index'),

    /**
     * 模板3 德科
     * themeTemplateThree
     */
    contactDetailThree: () => import('@/components/themeTemplateThree/page/detail/contact'),
    aboutDetailThree: () => import('@/components/themeTemplateThree/page/detail/index'),
    pageCartThree: () => import('@/components/themeTemplateThree/page/cart/index'),
    pageHomeThree: () => import('@/components/themeTemplateThree/page/home'),
    pageOrderDetailThree: () => import('@/components/themeTemplateThree/page/order/detail'),
    pageOrderThree: () => import('@/components/themeTemplateThree/page/order/index'),
    pageProDetailThree: () => import('@/components/themeTemplateThree/page/product/proDetail.vue'),
    pageProductThree: () => import('@/components/themeTemplateThree/page/product/index.vue'),
    pageAddressThree: () => import('@/components/themeTemplateThree/page/address/index'),
    newDetailThree: () => import('@/components/themeTemplateThree/page/detail/newDetail'), // 展会计划详情

    /**
     * 模板4 凯荣丰
     * themeTemplateFour
     */
    contactDetailFour: () => import('@/components/themeTemplateFour/page/detail/index'),
    aboutDetailFour: () => import('@/components/themeTemplateFour/page/detail/index'),
    pageCartFour: () => import('@/components/themeTemplateFour/page/cart/index'),
    pageHomeFour: () => import('@/components/themeTemplateFour/page/home'),
    pageOrderDetailFour: () => import('@/components/themeTemplateFour/page/order/detail'),
    pageOrderFour: () => import('@/components/themeTemplateFour/page/order/index'),
    pageProDetailFour: () => import('@/components/themeTemplateFour/page/product/proDetail.vue'),
    pageProductFour: () => import('@/components/themeTemplateFour/page/product/index.vue'),
    pageAddressFour: () => import('@/components/themeTemplateFour/page/address/index'),
    pageNewsFour: () => import('@/components/themeTemplateFour/page/news/index'),
    pageNewsDetailFour: () => import('@/components/themeTemplateFour/page/news/detail.vue'),
    pageVideoFour: () => import('@/components/themeTemplateFour/page/video/index'),
    pageLoginFour: () => import('@/components/themeTemplateFour/page/login/index'),
    pageBrandFour: () => import('@/components/themeTemplateFour/page/brand/detail.vue'),


    /**
     * 模板5 锦泰国际
     * themeTemplateFive
     */
    contactDetailFive: () => import('@/components/themeTemplateFive/page/detail/index'),
    aboutDetailFive: () => import('@/components/themeTemplateFive/page/detail/index'),
    pageCartFive: () => import('@/components/themeTemplateFive/page/cart/index'),
    pageHomeFive: () => import('@/components/themeTemplateFive/page/home'),
    pageOrderDetailFive: () => import('@/components/themeTemplateFive/page/order/detail'),
    pageOrderFive: () => import('@/components/themeTemplateFive/page/order/index'),
    pageProDetailFive: () => import('@/components/themeTemplateFive/page/product/proDetail.vue'),
    pageProductFive: () => import('@/components/themeTemplateFive/page/product/index.vue'),
    pageAddressFive: () => import('@/components/themeTemplateFive/page/address/index'),

    /**
     * 模板6 国安
     * themeTemplateSix
     */
    contactDetailSix: () => import('@/components/themeTemplateSix/page/detail/contact.vue'),
    aboutDetailSix: () => import('@/components/themeTemplateSix/page/detail/about.vue'),
    pageCartSix: () => import('@/components/themeTemplateSix/page/cart/index'),
    pageHomeSix: () => import('@/components/themeTemplateSix/page/home'),
    pageOrderDetailSix: () => import('@/components/themeTemplateSix/page/order/detail'),
    pageOrderSix: () => import('@/components/themeTemplateSix/page/order/index'),
    pageProDetailSix: () => import('@/components/themeTemplateSix/page/product/proDetail.vue'),
    pageProductSix: () => import('@/components/themeTemplateSix/page/product/index.vue'),
    pageAddressSix: () => import('@/components/themeTemplateSix/page/address/index'),
    pageNewsSix: () => import('@/components/themeTemplateSix/page/news/index'),
    pageNewsDetailSix: () => import('@/components/themeTemplateSix/page/news/detail.vue'),
    pageVideoSix: () => import('@/components/themeTemplateSix/page/video/index'),
    pageAuditSix: () => import('@/components/themeTemplateSix/page/audit/index'),
    pageNewProSix: () => import('@/components/themeTemplateSix/page/product/newPro'),
    pageHotProSix: () => import('@/components/themeTemplateSix/page/product/hotPro'),

    /**
     * 模板7 南通拇指
     * themeTemplateSeven
     */
    contactDetailSeven: () => import('@/components/themeTemplateSeven/page/detail/contact.vue'),
    aboutDetailSeven: () => import('@/components/themeTemplateSeven/page/detail/about.vue'),
    pageCartSeven: () => import('@/components/themeTemplateSeven/page/cart/index'),
    pageHomeSeven: () => import('@/components/themeTemplateSeven/page/home'),
    pageOrderDetailSeven: () => import('@/components/themeTemplateSeven/page/order/detail'),
    pageOrderSeven: () => import('@/components/themeTemplateSeven/page/order/index'),
    pageProDetailSeven: () => import('@/components/themeTemplateSeven/page/product/proDetail.vue'),
    pageProductSeven: () => import('@/components/themeTemplateSeven/page/product/index.vue'),
    pageAddressSeven: () => import('@/components/themeTemplateSeven/page/address/index'),
    pageNewsSeven: () => import('@/components/themeTemplateSeven/page/news/index'),
    pageNewsDetailSeven: () => import('@/components/themeTemplateSeven/page/news/detail.vue'),
    pageVideoSeven: () => import('@/components/themeTemplateSeven/page/video/index'),
    pageAuditSeven: () => import('@/components/themeTemplateSeven/page/audit/index'),

        /**
     * 模板8 多明戈
     * themeTemplateEight
     */
    contactDetailEight: () => import('@/components/themeTemplateEight/page/detail/contact.vue'),
    aboutDetailEight: () => import('@/components/themeTemplateEight/page/detail/about.vue'),
    pageCartEight: () => import('@/components/themeTemplateEight/page/cart/index'),
    pageHomeEight: () => import('@/components/themeTemplateEight/page/home'),
    pageOrderDetailEight: () => import('@/components/themeTemplateEight/page/order/detail'),
    pageOrderEight: () => import('@/components/themeTemplateEight/page/order/index'),
    pageProDetailEight: () => import('@/components/themeTemplateEight/page/product/proDetail.vue'),
    pageProductEight: () => import('@/components/themeTemplateEight/page/product/index.vue'),
    pageAddressEight: () => import('@/components/themeTemplateEight/page/address/index'),
    pageNewsEight: () => import('@/components/themeTemplateEight/page/news/index'),
    pageNewsDetailEight: () => import('@/components/themeTemplateEight/page/news/detail.vue'),
    pageVideoEight: () => import('@/components/themeTemplateEight/page/video/index'),
    pageAuditEight: () => import('@/components/themeTemplateEight/page/audit/index'),
    pageHotproductEight: () => import('@/components/themeTemplateEight/page/hotProduct/index.vue'),//热销产品
  }
}
