/*
 * @Description: 德科俄语
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-04-04 19:29:50
 */
export default {
    "DK.navTitle.Home":"Главная  сайта",
    "DK.navTitle.Produch":"продукции Показать",
    "DK.navTitle.Cart":"Тележка покупок",
    "DK.navTitle.Exhibition":"План выставок",
    "DK.navTitle.Message":"Связаться / О нас",
    "DK.logoBox.placeholder":"Введите ключевые слова продук",
    "DK.ProductNumber":"Номер продукта",
    "DK.amount":"Количество",
    "DK.contactUs":"Свяжитесь с нами",
    "DK.understandDeke":"Понимание Деко",
    "DK.telephone":"Самолёт",
    "DK.phoneAndWeixin":"Мобильный микрофон",
    "DK.PleaseOrderNum":"Введите номер заказа.",
    "DK.ArticleNo":"Номер",
    "DK.TotalVolume":"Общий объем",
    "DK.totalQTY": "总数量",
    "DK.totalItems": "总款数",
    "DK.ExhibitionRelatedProducts":"Выставка ассоциированных продуктов",
    "DK.ProductsVideo":"Видео продукции",
    "DK.ReleaseDate":"Дата выпуска",
    "DK.inquirySheet":"Запрос",
    "DK.SendInquiry":"Запрос",
    "DK.productPrice":"Цены продукцию",
    "DK.OuterBoxCBM":"外箱材积",
    "DK.OuterBoxCUFT":"外箱体积",
    "DK.Innerboxes":"Количество ячеек",
    "DK.OuterCapacity":"Загрузка наружного ящика",
    "DK.productDetails":"Подробности продукции",
    "DK.similarProduct":"Похожие продукты",
    "DK.MyRFQ":"Мой запрос.",
    "DK.enquiry":"Запрос цены",
    "DK.enquirys": "请留下您的电子邮件，我们会与您联系",
    "DK.contact":"Контактная",
    "DK.Description":"Имя",
    "DK.OuterQTY":"外箱装量",
    "DK.LastSevenDays": "最近七天",
    "DK.All": "全部",
    "DK.PleaseEmial": "请输入邮箱",
    "DK.PleaseCode": "请输入验证码",
    "DK.PleasePhone": "请输入手机号",
    "DK.PleaseName": "请输入用户名",
    "DK.userName": "用户名",
    "DK.PleaseAddress": "请输入联系地址",
}   
