/*
 * @Description: 国安中文
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-09-25 18:07:30
 */
export default {
    "NT.login": "登录",
    "NT.register": "注册",
    "NT.logout": "退出",
    "NT.MyOrder": "我的订单",
    "NT.logoBox.placeholder": "关键词",
    "NT.logoBox.search": "搜索",
    "NT.logoBox.AdvancedSearch": "高级搜索",
    "NT.nav.Products": "产品",
    "NT.nav.news": "新闻",
    "NT.ViewPrice": "查看价格",
    "NT.SameFactory": "同一个工厂",
    "NT.newPro": "最新产品",
    "NT.AnotherBatch": "换一批",
    "NT.ViewMore": "查看更多",
    "NT.hotPro": "热销产品",
    "NT.hotVidio": "热点视频",
    "NT.proUnBoxVidio": "开箱视频",
    "NT.Search": "搜索",
    "NT.Reset": "重置",
    "NT.ItemNO": "项目编号",
    "NT.MTCode": "公司编号",
    "NT.FTYCode": "出厂货号",
    "NT.Kind": "种类",
    "NT.Size": "产品规格",
    "NT.Price": "价格",
    "NT.Date": "日期",
    "NT.Sort": "排序",
    "NT.DateDesc": "日期降序",
    "NT.DateAsc": "日期升序",
    "NT.PriceDesc": "价格降序",
    "NT.PriceAsc": "价格升序",
    "NT.NO.Desc": "公司编号降序",
    "NT.NO.Asc": "公司编号升序",
    "NT.PleaseEnterTheProductNameKeyword": "请输入产品名称关键字",
    "NT.PleaseSelect": "请选择",
    "NT.PleaseLength": "长",
    "NT.PleaseWidth": "宽",
    "NT.PleaseHeight": "高",
    "NT.PleaseMinPrice": "最小价格",
    "NT.PleaseMaxPrice": "最大价格",
    "NT.PleaseStartDate": "开始日期",
    "NT.PleaseEndDate": "结束日期",
    "NT.UserAccount": "用户名",
    "NT.password": "密码",
    "NT.GROUPSEARCH": "综合搜索",
    "NT.Video_playback": "视频播放",
    "NT.loginMsg": "访问您的帐户和订单历史记录",
    "NT.onUserAccount": "没有账号",
    "NT.SignUp": "立即注册",
    "NT.ForgetPassword": "忘记密码",
    "NT.resetPasswords": "重置密码",
    "NT.emailAddress": "电子邮箱",
    "NT.verifyCode": "验证码",
    "NT.getVerifyCode": "获取验证码",
    "NT.newPassword": "新密码",
    "NT.confirmPassword": "确认密码",
    "NT.Submit": "提交",
    "NT.SignUpAccount": "注册账号",
    "NT.LoginOnly": "仅用于登录",
    "NT.Name": "姓名",
    "NT.NameMsg": "请输入您的姓名，2~30个字符",
    "NT.passwordMsg": "至少6个字符",
    "NT.requiredFields": "*必填项",
    "NT.Email": "邮箱",
    "NT.EmailMsg": "用于登录，注册后我们将向此邮箱发送邮件以验证有效性",
    "NT.gender": "性别",
    "NT.man": "男士",
    "NT.woman": "女士",
    "NT.customerSource": "客户来源",
    "NT.salesman": "销售员",
    "NT.Country": "国家",
    "NT.Company": "公司",
    "NT.duty": "职务",
    "NT.tel": "电话",
    "NT.mobile": "手机号",
    "NT.fax": "传真",
    "NT.Yahoo": "雅虎",
    "NT.website": "网站",
    "NT.address": "地址",
    "NT.postalCode": "邮政编码",
    "NT.ExistingAccount": "已有账号",
    "NT.DirectLogin": "直接登录",
    "NT.productPrice": "产品价格",
    "NT.ProductNumber": "产品编号",
    "NT.Packing": "包装方式",
    "NT.OuterBoxCBM": "外箱体积",
    "NT.OuterBoxCUFT": "外箱材积",
    "NT.Innerboxes": "内盒个数",
    "NT.OuterQTY": "外箱装量",
    "NT.GWAndNW": "毛重 / 净重",
    "NT.productSize": "产品规格",
    "NT.packageSize": "包装规格",
    "NT.cartonSize": "外箱规格",
    "NT.addCart": "添加购物车",
    "NT.delCart": "移出购物车",
    "NT.product": "产品",
    "NT.NewsUpdates": "新闻动态",
    "NT.ReleaseDate": "发布日期",
    "NT.MyCart": "我的购物车",
    "NT.ID": "ID",
    "NT.picture": "图片",
    "NT.ArticleNo": "货号",
    "NT.Description": "名称",
    "NT.CartOuterQTY": "外箱装量",
    "NT.CBM": "体积",
    "NT.CTNS": "箱数",
    "NT.totalQTY": "总数量",
    "NT.TotalVolume": "总体积",
    "NT.total": "合计",
    "NT.delete": "删除",
    "NT.checkAll": "全选",
    "NT.clear": "清空",
    "NT.totalItems": "总款数",
    "NT.totalCTNS": "总箱量",
    "NT.noData": "暂无数据",
    "NT.totalPrice": "总价",
    "NT.systemTip": "系统提示",
    "NT.confirm": "确定",
    "NT.cancel": "取消",
    "NT.deleteData": "是否删除该条数据？",
    "NT.clearCartData": "是否清空全部购物车？",
    "NT.submitTip": "确认提交订单？",
    "NT.MyOrder": "我的订单",
    "NT.PleaseOrderNum": "请输入订单号",
    "NT.number": "单号",
    "NT.viewDetails": "查看详情",
    "NT.orderTime": "创建时间",
    "NT.linkman": "联系人",
    "NT.sendDate": "发货时间",
    "NT.expressNumber": "快递单号",
    "NT.orderStatus": "订单状态",
    "NT.unshipped": "未发货",
    "NT.shipped": "已发货",
    "NT.SiteNavigation": "网站导航",
    "NT.ContactUs": "联系我们",
    "NT.FollowUs": "关注我们",
    "NT.Landline": "座机",
    "NT.Email": "邮箱",
    "NT.Phone": "手机",
    "NT.WeChat": "微信号",
    "NT.Technic": "技术支持",
    "NT.Software": "深圳宏升软件技术开发有限公司",
    "NT.detailBtn": "产品详情",
    "NT.noDetailBtn": "暂无产品详情",
    "NT.classify": "产品分类",
    "NT.exclusive": "专属推荐",
    "NT.loading": "加载中...",
    "NT.updateTime": "更新时间",
    "NT.SearchMsg": "以上搜索条件可选任意一项或多项综合搜索",
    "NT.MessageTitle": "在线提交您的需求",
    "NT.MessageMsg": "我们将在24小时内回复您的电子邮件，节假日除外",
    "NT.MessageName": "你的名字",
    "NT.MessageTel": "你的手机",
    "NT.MessageEmail": "你的邮箱",
    "NT.MessageNeeds": "你的需求",
    "NT.SendInquiry": "发询价单",
    "NT.EnquiryList": "询价单",
    "NT.enquiry": "询价",
    "NT.MyEnquiryList": "我的询价单",
    "NT.Remark": "备注",
    "NT.Enquirys": "请留下您的电子邮件，我们会与您联系",
    "NT.proClass": "产品分类",
    "NT.submitOrder": "确认提交询单？",


    "NT.Co-branding": "合作品牌",
    "NT.Co-brandingMsg": "已经有100余家企业，与我司进行深入合作",
    "NT.recommendPro": "推荐产品",
    "NT.Blog": "博客",
    "NT.More": "更多",
    "NT.nav.Home": "主页",
    "NT.nav.category": "类目",
    "NT.nav.Hot": "热卖",
    "NT.nav.Gift": "礼物",
    "NT.nav.New": "新产品",
    "NT.nav.video": "视频",
    "NT.nav.Blog": "博客",
    "NT.nav.About": "关于我们",
    "NT.nav.contact": "联系我们",
    "NT.nav.FooterNew": "新品",
    "NT.news.title": "所有博客",
    "NT.noMore": "没有更多了",
    "NT.top.cart": "购物车",
    "NT.fomcat.title": "为什么选择fomcat品牌",
    "NT.fomcat.titleMsg": "优势尽在这里！ Fomcat玩具批发商的优势不容忽视，让我们看看为什么我们是您的最佳合作伙伴！",
    "NT.fomcat1.title1": "多样化的产品系列",
    "NT.fomcat1.Msg1": "涵盖儿童玩具、遥控玩具、模型和收藏品等，满足不同客户需求。无论您的店铺面向哪个年龄段，我们都有完美的选择！",
    "NT.fomcat2.title1": "材料品质保证",
    "NT.fomcat2.Msg1": "我们精选优质材料，为孩子们打造安全可靠的玩具，经受市场检验，让家长和孩子们安心享乐！",
    "NT.fomcat3.title1": "竞争力的批发价格",
    "NT.fomcat3.Msg1": "我们提供极具竞争力的批发价格，为您带来更大的利润空间！",
    "NT.fomcat4.title1": "轻松合作",
    "NT.fomcat4.Msg1": "随时与我们联系，我们的团队将高效解决您的问题和需求。我们的目标是建立长期成功的合作关系！",
    "NT.fomcat5.title1": "方便订购",
    "NT.fomcat5.title2": "贴心服务",
    "NT.fomcat5.Mag1": "您的成功就是我们的成功！选择Fomcat品牌，与我们一起打造美好的玩具乐园！",



}
