/*
 * @Description: vuex方法引入
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-12-08 17:28:57
 */
export const SET_ENQUIRY = 'set_enquiry' // 添加商品到询价单
export const DELETE_ENQUIRY = 'delete_enquiry' // 添加商品到询价单
export const ADD_CART = 'add_cart' // 添加商品到购物车
export const DELETE_CART = 'delete_cart' // 从购物车删除商品
export const CLEAR_CART = 'clear_cart' // 清空购物车
export const SET_LANGUAGE = 'set_language' // 设置语言
export const SET_USERINFO = 'set_userInfo' //设置用户信息
export const DEL_USERINFO = 'del_userInfo'
export const INSERT_CART = 'insert_cart' // 加购埋点
export const SET_SETTING = 'set_setting' // 获取网站配置
export const SET_MENUS = 'set_menus' // 设置分类菜单
export const SET_LANGUAGE_CONFIG = 'set_language_config' // 站点语言
export const SET_CART = 'set_cart'
export const TENANTID = 442778172989509  // 392898957606981 288762652057669  392919041949765 142307070918780 414430149820485 440276425969733