/*
 * @Description: 国安英文
 * @Author: zml
 * @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-09-25 18:04:11
 */
export default {
    "NT.login":"Login",
    "NT.register":"Register",
    "NT.logout":"Logout",
    "NT.MyOrder":"My Order",
    "NT.logoBox.placeholder": "Keyword",
    "NT.logoBox.search": "Search",
    "NT.logoBox.AdvancedSearch": "Advanced Search",
    "NT.nav.Products": "PRODUCTS",
    "NT.nav.news": "NEWS",
    "NT.ViewPrice": "View Price",
    "NT.SameFactory": "Same Factory",
    "NT.newPro": "NEW ARRIVIALS",
    "NT.AnotherBatch": "Another Batch",
    "NT.ViewMore": "View More",
    "NT.hotPro": "HOT ITEMS",
    "NT.hotVidio": "HOT ITEM VODEO",
    "NT.proUnBoxVidio": "PRODUCT UNBOXING VIDEO",
    "NT.Search": "SEARCH",
    "NT.Reset": "RESET",
    "NT.ItemNO": "Item NO.",
    "NT.MTCode": "MT Code",
    "NT.FTYCode": "FTY Code",
    "NT.Kind": "Kind",
    "NT.Size": "Size",
    "NT.Price": "Price",
    "NT.Date": "Date",
    "NT.Sort": "Sort",
    "NT.DateDesc": "DATE DESC",
    "NT.DateAsc": "DATE ASC",
    "NT.PriceDesc": "PRICE DESC",
    "NT.PriceAsc": "PRICE ASC",
    "NT.NO.Desc": "NO. DESC",
    "NT.NO.Asc": "NO. ASC",
    "NT.PleaseEnterTheProductNameKeyword": "Please enter the product name keyword",
    "NT.PleaseSelect": "Please select",
    "NT.PleaseLength": "Length",
    "NT.PleaseWidth": "Width",
    "NT.PleaseHeight": "Height",
    "NT.PleaseMinPrice": "Min price",
    "NT.PleaseMaxPrice": "Max price",
    "NT.PleaseStartDate": "Start date",
    "NT.PleaseEndDate": "End date",
    "NT.UserAccount": "User Account",
    "NT.password": "Password",
    "NT.GROUPSEARCH": "GROUPSEARCH",
    "NT.Video_playback": "Video playing",
    "NT.loginMsg": "Access your account and order history",
    "NT.onUserAccount": "No account",
    "NT.SignUp": "Sign up",
    "NT.ForgetPassword": "Forget password",
    "NT.resetPasswords": "Reset password",
    "NT.emailAddress": "Email address",
    "NT.verifyCode": "Verify code",
    "NT.getVerifyCode": "Get code",
    "NT.newPassword": "New password",
    "NT.confirmPassword": "Confirm password",
    "NT.Submit": "Submit",
    "NT.SignUpAccount": "Sign up",
    "NT.LoginOnly": "Login only",
    "NT.Name": "Name",
    "NT.NameMsg": "Please enter your name, 2 to 30 characters",
    "NT.passwordMsg": "At least 6 characters",
    "NT.requiredFields": "*Required fields",
    "NT.Email": "Email",
    "NT.EmailMsg": "Please enter email. Used for login",
    "NT.gender": "Gender",
    "NT.man": "Mr.",
    "NT.woman": "Ms.",
    "NT.customerSource": "Customer source",
    "NT.salesman": "Contact Salesman",
    "NT.Country": "Country",
    "NT.Company": "Company",
    "NT.duty": "Job Titel",
    "NT.tel": "Tel",
    "NT.mobile": "Mobile",
    "NT.fax": "Fax",
    "NT.Yahoo": "Yahoo",
    "NT.website": "Website",
    "NT.address": "Address",
    "NT.postalCode": "Postal code",
    "NT.ExistingAccount": "Existing account",
    "NT.DirectLogin": "Direct login",
    "NT.productPrice": "Price",
    "NT.ProductNumber": "Item NO.",
    "NT.Packing": "Packing",
    "NT.OuterBoxCBM": "CBM",
    "NT.OuterBoxCUFT": "CUFT",
    "NT.Innerboxes": "Inner Boxes",
    "NT.OuterQTY": "Outer QTY",
    "NT.GWAndNW": "G.W/N.W",
    "NT.productSize": "Product Size",
    "NT.packageSize": "Package Size",
    "NT.cartonSize": "Carton Size",
    "NT.addCart": "Add To Cart",
    "NT.delCart": "Remove from Cart",
    "NT.product": "PRODUCTS",
    "NT.NewsUpdates": "News Updates",
    "NT.ReleaseDate":"Release Date",
    "NT.MyCart":"My Cart",
    "NT.ID":"ID",
    "NT.picture":"Picture",
    "NT.ArticleNo":"Item No.",
    "NT.Description":"Product Name",
    "NT.CartOuterQTY":"Outer QTY",
    "NT.CBM":"CBM",
    "NT.CTNS":"CTNS",
    "NT.totalQTY": "Total QTY",
    "NT.TotalVolume":"Total CBM",
    "NT.total":"Total",
    "NT.delete":"Delete",
    "NT.checkAll":"Check All",
    "NT.clear":"Clear",
    "NT.totalItems": "Total Items",
    "NT.totalCTNS": "Total CTNS",
    "NT.noData": "No Data",
    "NT.totalPrice": "Total Price",
    "NT.systemTip": "Tip",
    "NT.confirm": "Confirm",
    "NT.cancel": "Cancel",
    "NT.deleteData": "Do you want to delete the data?",
    "NT.clearCartData": "Do you want to empty your shopping cart?",
    "NT.submitTip": "Confirm to submit the order?",
    "NT.MyOrder": "My Order",
    "NT.PleaseOrderNum": "Please enter the order number",
    "NT.number": "Number",
    "NT.viewDetails": "View Details",
    "NT.orderTime": "Creation time",
    "NT.linkman": "Linkman",
    "NT.sendDate": "Delivery Date",
    "NT.expressNumber": "Tracking Number",
    "NT.orderStatus": "Order Status",
    "NT.unshipped": "Unshipped",
    "NT.shipped": "Shipped",
    "NT.SiteNavigation": "Site Navigation",
    "NT.ContactUs": "Contact us",
    "NT.FollowUs": "Follow us",
    "NT.Landline": "Landline",
    "NT.Email": "E-mail",
    "NT.Phone": "Phone",
    "NT.WeChat": "WeChat",
    "NT.Technic": "Technic Sustain",
    "NT.Software": "HongSheng Soft",
    "NT.detailBtn": "Product details",
    "NT.noDetailBtn": "No product details",
    "NT.classify": "CATALOG",
    "NT.exclusive": "EXCLUSIVE RECOMMENDATIONS",
    "NT.loading": "Loading...",
    "NT.updateTime": "UpdateTime",
    "NT.SearchMsg": "The above search criteria can be any one or more comprehensive searches",
    "NT.MessageTitle": "Submit your requirements online",
    "NT.MessageMsg": "We will reply to your email within 24 hours, excluding holidays",
    "NT.MessageName": "YOUR NAME",
    "NT.MessageTel": "YOUR PHONE",
    "NT.MessageEmail": "YOUR EMAIL",
    "NT.MessageNeeds": "YOUR NEEDS",
    "NT.SendInquiry": "Send Inquiry",
    "NT.EnquiryList": "ENQUIRY",
    "NT.enquiry": "Enquiry",
    "NT.MyEnquiryList": "My Inquiry Form",
    "NT.Remark": "Remark",
    "NT.Enquirys": "Leave your email and we'll get back to you",
    "NT.proClass": "Product Category",
    "NT.submitOrder": "Confirm submitting the inquiry?",



    "NT.Co-branding": "Co-branding",
    "NT.Co-brandingMsg": "More than 100 companies have conducted in-depth cooperation with our company",
    "NT.recommendPro": "Recommended products",
    "NT.Blog": "Blog",
    "NT.More": "Más",
    "NT.nav.Home": "Home",
    "NT.nav.category": "Category",
    "NT.nav.Hot": "Hot Selling",
    "NT.nav.Gift": "Gift",
    "NT.nav.New": "New Product",
    "NT.nav.video": "Video",
    "NT.nav.Blog": "Blog",
    "NT.nav.About": "About Us",
    "NT.nav.contact": "Contact Us",
    "NT.nav.FooterNew": "New Product",
    "NT.news.title": "All Blogs",
    "NT.noMore": "There's no more",
    "NT.top.cart": "Cart",
    "NT.fomcat.title": "Why choose the Fomcat brand",
    "NT.fomcat.titleMsg": "The advantages are all here! The advantages of Fomcat toy wholesalers cannot be ignored, let's see why we are your best partner!",
    "NT.fomcat1.title1": "Diversified product range",
    "NT.fomcat1.Msg1": "Covering children's toys, remote control toys, models and collectibles, meeting different customer needs. No matter which age group your store is targeting, we have the perfect choice!",
    "NT.fomcat2.title1": "Material quality assurance",
    "NT.fomcat2.Msg1": "We select high-quality materials to create safe and reliable toys for children, undergo market testing, and let parents and children enjoy themselves with peace of mind!",
    "NT.fomcat3.title1": "Competitive wholesale prices",
    "NT.fomcat3.Msg1": "We offer highly competitive wholesale prices, bringing you greater profit margins!",
    "NT.fomcat4.title1": "Easy collaboration",
    "NT.fomcat4.Msg1": "Feel free to contact us and our team will efficiently solve your problems and needs. Our goal is to establish a long-term and successful partnership!",
    "NT.fomcat5.title1": "Convenient ordering",
    "NT.fomcat5.title2": "Intimate service",
    "NT.fomcat5.Mag1": "Your success is our success! Choose the Fomcat brand and work with us to create a beautiful toy paradise!",



}   
